export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const consola = useConsola()

  consola.debug('[subscription] start')

  const sessionStore = useSessionStore()

  if (sessionStore.isTrialExpired) {
    consola.debug('trial expired')
    return navigateTo('/expired')
  }

  consola.debug('[subscription] end')
})
